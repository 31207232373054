import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { ContactUsField, Option } from "src/lib/shared/types";
import styles from "./styles.module.scss";

export default function MultiSelect({
    field,
    onChange,
    fieldClassName,
    value,
    errorText,
    noteText,
}: {
    field: ContactUsField;
    onChange: (val: string) => void;
    fieldClassName: string;
    value: string;
    errorText?: string | null;
    noteText?: string | null;
}): ReactElement {
    const [show, setShow] = useState(false);
    const [searchableValue, setSearchableValue] = useState("");
    const [checkboxValue, setCheckboxValue] = useState(
        field.options?.filter((option) => value.includes(option.value)) ?? [],
    );

    const handleCheckboxChange = (value: string, remove?: boolean) => {
        const tempValue = [...(checkboxValue as Option[])];
        if (remove) {
            onChange(
                tempValue
                    .filter((option) => option.value !== value)
                    .map((el) => el.value)
                    .join(","),
            );
            return;
        }
        if (tempValue.find((option) => option.value === value)) {
            onChange(
                tempValue
                    .filter((option) => option.value !== value)
                    .map((el) => el.value)
                    .join(","),
            );
        } else {
            onChange(
                [...tempValue.map((option) => option.value), value].join(","),
            );
        }
    };

    const renderOptions = () =>
        field?.options
            ?.filter((option) =>
                option.label
                    .toLowerCase()
                    .includes(searchableValue.trim().toLowerCase()),
            )
            .map((option) => (
                <div className={styles["option"]} key={option.value}>
                    <input
                        type="checkbox"
                        id={`${field.name}-${option.value}`}
                        value={option.value}
                        name={field.name}
                        //@ts-ignore
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        onClick={(e: ChangeEvent<HTMLInputElement>) =>
                            handleCheckboxChange(e.target.value)
                        }
                        defaultChecked={
                            !!checkboxValue?.find(
                                (el) => el.value === option.value,
                            )
                        }
                    />
                    <label
                        key={option.value}
                        htmlFor={`${field.name}-${option.value}`}
                    >
                        <span className={styles["box"]}>
                            <svg viewBox="0,0,50,50">
                                <path d="M5 30 L 20 45 L 45 5"></path>
                            </svg>
                        </span>

                        <span
                            className="text-sm flex items-center"
                            style={{ transform: "translateY(1px)" }}
                        >
                            {option.label}
                        </span>
                    </label>
                </div>
            ));
    useEffect(() => {
        setCheckboxValue(
            field.options?.filter((option) => value.includes(option.value)) ??
                [],
        );
    }, [value]);

    const changeInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchableValue(e.target.value);
        setShow((prev) => {
            if (prev === false) {
                return true;
            }
            return prev;
        });
    };
    return (
        <div
            className={styles["multi-select"]}
            tabIndex={0}
            onBlur={(e) => {
                if (e.relatedTarget === null) {
                    setShow(false);
                    setSearchableValue("");
                }
            }}
        >
            <div
                className={`${styles["multi-select-input-container"]}  ${fieldClassName}`}
                onClick={() => {
                    setShow((prev) => !prev);
                }}
                style={{ padding: "3px 15px" }}
            >
                <div className={styles["multi-select-tags"]}>
                    {checkboxValue?.map((option) => (
                        <span key={option.value} className={styles["tag"]}>
                            <span>{option.label}</span>
                            <button
                                type="button"
                                className="px-[6px] cursor-pointer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleCheckboxChange(option.value, true);
                                }}
                            >
                                <X />
                            </button>
                        </span>
                    ))}
                    <input
                        type="text"
                        placeholder={
                            field.placeholder
                                ? field.placeholder
                                : "Select Multiple Options"
                        }
                        className={styles["multi-select-input"]}
                        value={searchableValue}
                        onChange={changeInputHandler}
                    ></input>
                </div>
            </div>
            {show && (
                <div
                    className={`${styles["multi-select-dropdown"]}  ${styles["checkbox"]}`}
                >
                    {renderOptions()?.length === 0 ? (
                        <span className="flex justify-center items-center p-4">
                            No Matches Found
                        </span>
                    ) : (
                        renderOptions()
                    )}
                </div>
            )}
            <span className="pt-2 block">
                {errorText && (
                    <div className={`${styles["error-message"]}`}>
                        <span>{errorText}</span>
                    </div>
                )}
                {noteText && (
                    <div className={`${styles["field-note"]}`}>
                        <span>{noteText}</span>
                    </div>
                )}
            </span>
        </div>
    );
}

const X = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x"
        >
            <line x1="9" y1="3" x2="3" y2="9"></line>
            <line x1="3" y1="3" x2="9" y2="9"></line>
        </svg>
    );
};
