import Loader from "@components/shared/loader";
import React, { ReactElement, ReactNode } from "react";
import styles from "./button.module.scss";

interface Props {
    className?: string;
    children: ReactNode;
    onClick?: () => void;
    icon?: ReactElement;
    target?: React.HTMLAttributeAnchorTarget | undefined;
    href?: string;
    loading?: boolean;
    style: React.CSSProperties | undefined;
    type?: "button" | "submit" | "reset" | undefined;
}

export function Button(props: Props): ReactElement {
    const {
        className = "",
        children,
        onClick,
        icon,
        href,
        target,
        loading,
        style,
        type,
    } = props;

    return href ? (
        <a
            target={target}
            href={href}
            className={`${styles["button"]} ${className ?? ""}`}
            style={style}
        >
            {!loading ? (
                <span className={styles["inner"]}>
                    <span className={styles["label"]}>{children}</span>
                    <span className={styles["icon"]}>{icon}</span>
                </span>
            ) : (
                <Loader size={25} />
            )}
        </a>
    ) : (
        <button
            type={type}
            style={style}
            className={`${styles["button"]}  ${className}`}
            onClick={onClick}
        >
            {!loading ? (
                <span className={styles["inner"]}>
                    <span className={styles["label"]}>{children}</span>
                    <span className={styles["icon"]}>{icon}</span>
                </span>
            ) : (
                <Loader size={25} />
            )}
        </button>
    );
}
